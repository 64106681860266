<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="任务名称">
              <a-input placeholder="请输入" v-model="queryParam.taskName" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="15">
            <a-form-item label="已删除">
              <a-select v-model="queryParam.delFlag" allowClear>
                <a-select-option :value="false">否</a-select-option>
                <a-select-option :value="true">是</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = { delFlag: false })">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button :disabled="selectedRowKeys.length === 0" type="danger" icon="delete" @click="delByIds(selectedRowKeys)"
        >删除</a-button
      >
    </div>

    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="result" slot-scope="text">
        {{ text | resultFilter }}
      </span>
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="action" slot-scope="text, record">
        <!-- <a-divider v-if="editEnabel" type="vertical" />
        <a v-if="editEnabel" @click="handleEdit(record.id)">编辑</a> -->
        <a @click="handelAudit(record)">详情</a>
        <a-divider type="vertical" />
        <a @click="handFlow(record.procInstId)">历史</a>
        <a-divider v-if="!record.delFlag" type="vertical" />
        <a v-if="!record.delFlag" @click="delByIds([record.auditId])">删除</a>
      </span>
    </a-table>

    <!--    <leave-modal ref="leavemodal" @ok="handleOk" :audit="audit"/>-->
    <!--    <purchase-modal ref="purchasemodal" @ok="handleOk" :audit="false"/>-->
    <proc-flow-modal ref="modal" @ok="handleOk" />
  </a-card>
</template>

<script>
import { millsToTime } from '@/utils/util'
import { STable } from '@/components'
import { getTaskdone, delTaskLogic, processInsState, modalMap } from '@/api/activiti'
// import LeaveModal from './modules/LeaveModal.vue'
// import PurchaseModal from './modules/PurchaseModal.vue'
import ProcFlowModal from './modules/ProcFlowModal.vue'
const resultMap = {
  1: '处理中',
  2: '通过',
  3: '驳回',
  4: '撤销',
}
export default {
  name: 'Tasking',
  components: {
    STable,
    // LeaveModal,
    ProcFlowModal,
    // PurchaseModal
  },
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: { delFlag: false },
      // 表头
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'index' },
        },
        {
          title: '申请标题',
          dataIndex: 'title',
        },
        {
          title: '所属流程',
          dataIndex: 'procName',
        },
        {
          title: '任务名称',
          dataIndex: 'taskName',
        },
        {
          title: '申请人',
          dataIndex: 'applyer',
        },
        {
          title: '审批结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '耗时',
          dataIndex: 'duration',
          customRender: (text) => millsToTime(text),
        },
        {
          title: '指派时间',
          dataIndex: 'startTime',
        },
        {
          title: '审批时间',
          dataIndex: 'endTime',
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getTaskdone(Object.assign(parameter, this.queryParam))
      // },
      audit: false,
      previewVisible: false,
      previewImage: '',
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.delFlag, // Column configuration not to be checked
          },
        }),
      }
    },
  },
  filters: {
    resultFilter(status) {
      return resultMap[status]
    },
  },
  created() {
    this.getData()
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleOk() {
      this.getData()
      console.log('handleSaveOk')
    },
    handFlow(procInstId) {
      this.$refs.modal.show(procInstId)
    },
    handelAudit(record) {
      const modal = modalMap[record.procDefKey]
      this.$refs[modal].show(record)
    },
    handleState(record) {
      const state = record.isSuspended ? 'active' : 'suspend'
      processInsState(record.procInstId, state).then((res) => {
        if (res.code === 200) {
          record.isSuspended = !record.isSuspended
        }
      })
    },
    delByIds(ids) {
      if (ids.length > 0) {
        const _that = this
        _that.$confirm({
          title: '确认删除',
          content: '确认要删除选择的数据?',
          onOk() {
            delTaskLogic({ ids: ids.join(',') }).then((res) => {
              if (res.code === 200) {
                _that.$message.success(`删除成功`)
                _that.handleOk()
              } else {
                _that.$message.error(res.msg)
              }
            })
          },
          onCancel() {},
        })
      } else {
        this.$message.error('没有选择数据')
      }
      this.selectedRowKeys = []
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    query(){
      this.page.current = 1;
      this.getData()
    },
    // 重置
    reset(){
      this.queryParam = {
        delFlag: false
      }
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getTaskdone({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, rows } = res
            this.page.total = total
            this.tableData = rows
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
